import React from 'react'
import sectionImg from '../../assets/images/section-img.png'

const HealthCareSectionBlock = () => {
  return (
    <>
     <div className="  relative w-full min-h-[1px] px-[15px]
  flex-[0_0_100%] max-w-[100%]">
                <div className="   mb-[15px] p-[15px] flex items-center justify-between flex-col lg:px-[250px] text-center ">
                    <h2 className=' mb-6 text-[21px] md:text-[32px] capitalize 
            font-semibold text-[#2C2D3F] leading-[1.2]'>We Provide You The Best Treatment In Resonable Price</h2>
                   <img src={sectionImg} alt="section"/>
                   <p className=' md:text-[15px] text-[13px] text-[#888] 
            mt-[15px] leading-6 font-normal'>Each package tailored to meet your unique needs and provide comprehensive coverage.</p>
                </div>
            </div>
    </>
  )
}

export default HealthCareSectionBlock