import React from 'react'
import { IoSearchSharp } from "react-icons/io5";

const MissingMain = () => {
  return (
    <section className=' bg-[#fff] py-[50px] lg:py-[100px] lg:h-[561px] h-[475px] md:h-[465px] md:py-[70px]  w-full flex items-center justify-center'>
    <div className='px-[15px] lg:h-[361px] h-[376px] md:mx-[24px] lg:mx-auto  max-w-full flex flex-col justify-center items-center '>
      <div className=' flex flex-col items-center  justify-center text-center max-w-[1110px] mx-[400px]'>
							<h1 className=' text-[140px] drop-shadow-[3px_5px_2px_#3333] text-reddish font-bold leading-tight'>404</h1>
              <span className='block text-[25px] text-[#333] font-semibold leading-tight '>Oop's  sorry we can't find that page!</span>
							<p className=' text-[#757575] leading-6 font-normal py-[20px] px-[15px] text-sm'>Aenean eget sollicitudin lorem, et pretium felis. Nullam euismod diam libero, sed dapibus leo laoreet ut. Suspendisse potenti. Phasellus urna lacus</p>
							<form className="w-full relative">
								<input placeholder="Search from Here" type="text" className=' w-[400px] h-[50px] pr-[78px] pl-[30px] py-[0px]  border-none bg-[#f6f6f6]
                rounded-[5px] inline-block mr-[10px] font-normal text-sm'/>
								<button className="bg-reddish text py-[13px] px-[25px] w-[80px] h-[50px]
        capitalize rounded-[4px] text-[14px] cursor-pointer relative top-[-6px] font-medium
         text-white hover:bg-[#2C2D3F] duration-500" type="submit"><IoSearchSharp className='text-[22px] absolute left-[31px] top-[16px]'/></button>
							</form>
      </div>  
    </div>
  </section>
  )
}

export default MissingMain