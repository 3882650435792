import MainImg from '../../assets/images/articleStomach/MainImg.webp'
import Img1 from '../../assets/images/articleStomach/SectionImg1.webp'
import Img2 from '../../assets/images/articleStomach/SectionImg2.webp'
import Img3 from '../../assets/images/articleStomach/SectionImg3.webp'



export const postDetails={
    img:MainImg,
    title:"Stomach Troubles: How To Tell If It’s Food Poisoning Or Flu",
    views:"67K",
    date:"NOVEMBER 15, 2023",
    text:<>

                                        <p>An upset stomach will prompt most people to worry more about how to get better first and determine its cause second. However, various conditions can cause your gut to feel off, and figuring out which one you are dealing with lets you determine your best plan of action and how long it will take before you are back on your feet. In this article, we focus on the two most common causes of stomach troubles, namely food poisoning and stomach flu, how to tell if you are dealing with one or the other, and when to seek help from a Virtual Doctor In India.</p>    
                                        <b>An overview of food poisoning and stomach flu</b>
                                        <p>Viral gastroenteritis, better known as stomach flu, works similarly to many other viruses in that it gets passed around from person to person in large, close-quarters gatherings. One environment notorious for spreading stomach flu is a cruise ship where the enclosed space keeps passengers in close contact with each other at all times.</p>
                                        <p>On the other hand, food poisoning is caused by eating bad food ridden with bacteria due to either not being cooked properly or sitting out for too long. Apart from that, some foods may simply not agree with your stomach despite being safe for consumption and causing symptoms similar to food poisoning.</p>
                                        <b>How to differentiate between the two</b>
                                        <p>When examining symptoms alone, distinguishing between food poisoning and stomach flu can prove challenging because they virtually share the same side effects. These include diarrhoea (which can sometimes get bloody), vomiting, nausea, chills, and fever, with the latter two being exclusive to stomach flu. That said, telling the two apart becomes easier upon learning when your symptoms begin and how long they last.</p>
                                        <b>- Onset Of Symptoms</b>
                                        <p>The symptoms of food poisoning come on quickly, between two to six hours upon eating the spoiled food, while those that stem from stomach flu take longer due to its incubation period of 24-48 hours. Food poisoning may be easier to detect in certain situations because it comes in right after a recent event. Most people can usually remember what they ate a few hours ago and realise they may have eaten something they should not have, enabling them to trace their symptoms back to it.</p>
                                        <b>- Duration Of Symptoms</b>
                                        <p>Food poisoning symptoms come on fast but also run their course just as quickly. In contrast, stomach flu typically lingers for two days and sometimes even longer. Thus, if you are sure that you have food poisoning, you can expect it to be gone in no time, while you may have to bear with it for a little while if it is stomach flu.</p>
                                        <b>Tips to recover from food poisoning or stomach flu</b>
                                        <p>Getting plenty of rest and drinking lots of water will suffice to Treat Food Poisoning and stomach flu. Dehydration is the greatest risk when experiencing either of these conditions since your body will push out more fluid to eliminate the unwanted contaminants that enter your system, hence the importance of replacing lost fluids.</p>
                                        <p>Suppose your felt symptomThus, if your symptoms do not seem to be letting up or they feel like worsening, set a doctor consultation at your earliest convenience through the MYCLNQ app. From private ambulance transport to Virtual ART Test In India, MYCLNQ lets you access the medical services you need quickly and conveniently in just a few clicks. For more information about how the app works, feel free to reach out to us or check out our website.s include a fever. If so, your body temperature rises, which also burns more fluid. Apart from water, it is recommended to drink beverages rich in electrolytes to replace the ones you lost while dehydrated since electrolytes are what help your body retain the fluids you are drinking.</p>
                                        <p>Apart from rest and staying hydrated, rarely is medication necessary for these two since they are self-limited ailments, meaning you will get better over time as the conditions run their course.</p>
                                        <b>Conclusion</b>
                                        <p>While there is usually no need for medical intervention when you have a bout of food poisoning or the stomach flu, experiencing certain symptoms like an unusually high fever is a good reason to get checked out by your healthcare provider. In addition, not recovering even after 48 hours have passed is also a concern.</p>
                                        <p> Thus, if your symptoms do not seem to be letting up or they feel like worsening, set a doctor consultation at your earliest convenience through the MYCLNQ app. From private ambulance transport to Virtual ART Test In India, MYCLNQ lets you access the medical services you need quickly and conveniently in just a few clicks. For more information about how the app works, feel free to reach out to us or check out our website.</p>

    </>,
}

export const recentPost=[
    {
        id:1,
        img:Img1,
        heading:"Change In Eye Colour And Why It Warrants Medical Attention",
        date:"Nov 9, 2023",
        link:"/articleEye",
        border:true
    },
    {
        id:2,
        img:Img2,
        heading:"Treating Chronic Migraines: What To Do Next If Nothing Helps",
        date:"Oct 26, 2023",
        link:"/articleMigrane",
        border:true
    },
    {
        id:3,
        img:Img3,
        heading:"5 Home Remedies To Ease Your Jaw Pain And Find Relief",
        date:"Oct 20, 2023",
        link:"/articleRemedies",
        border:false
    },
    ]