import slide1 from '../../assets/images/clinicDetails/raziqueClinic/ENT.webp'
import slide2 from '../../assets/images/clinicDetails/raziqueClinic/ENT2.webp'


export const slides = [
    {
      url: slide1,
    },
    {
      url: slide2,
    },
  ];
  export const isActive=false;
  export const doctorDetails={
      name:'Dr.Razique Anwar',
      speciality:'ENT Surgeon',
      phoneNo:'8789917807'
  }

  export const clinicDetails={
   heading:'Welcome to Dr. Razique ENT Clinic - Your Trusted Partner in Otolaryngology',
   docName:'Dr.Razique Anwar',
   qualification:'M.B.B.S, M.S(ENT)',
   speciality:'ENT Surgeon',
   phoneNum:'8789917807',
   email:'razique.anwar@gmail.com',
   exp:'5 years',
   languages:'Hindi and English',
   clinicAddress: 'Jail Chowk,Near Petrol Pump,Muzaffarpur,Bihar 842001',
   clinicTimings: 'Everyday 10:00 AM - 02:00 PM, 06:00 PM - 09:30 PM',
   doctorBookingNum:4,
   details:<>
       <p className='mt-[20px]  text-[14px] text-[#757575] m-0 leading-[24px] font-normal'>At Dr. Razique ENT Clinic, we prioritize your ear, nose, and throat health. Understanding the importance of convenience, we now offer seamless audio and video consultation services, bringing expert ENT care directly to your doorstep, no matter where you are in India.</p>
  
                                  
    <p className=' mt-[20px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal '>Why Choose Dr. Razique ENT Clinic:
                                    </p>
                            <ol className="ml-3 list-decimal  text-[14px] text-[#757575] m-0 leading-[24px] font-normal">
                            <li><b>Virtual Consultations:</b> Connect with our experienced ENT specialists without leaving your home. Our audio and video consultation services are designed for your convenience, ensuring accessibility to expert care.</li>
                                        <li><b>Expert ENT Specialists:</b> Our team comprises highly qualified and skilled ENT specialists dedicated to delivering accurate diagnoses and personalized treatment plans tailored to your ear, nose, and throat health.</li>
                                        <li><b>Comprehensive ENT Care: </b> Whether it's common issues or specialized treatments, we provide a wide range of ENT services to address your concerns. From hearing problems to sinus issues, our clinic is equipped to handle various conditions.</li>
                                        <li><b>Patient-Centric Approach: </b> Dr. Razique ENT Clinic, we prioritize a patient-centric approach. Our aim is to guide and support you throughout your ENT health journey, making the process as smooth as possible.</li>
                                        <li><b>Cutting-Edge Technology:</b>  Staying at the forefront of ENT advancements, we integrate state-of-the-art technology for precise diagnostics and effective treatments. Your well-being is our top priority.</li>
                                    
                                    </ol>
                                    <p className=' mt-[20px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal '>How It Works:
                                    </p>
                                    <ol class="ml-3 list-decimal  text-[14px] text-[#757575] m-0 leading-[24px] font-normal">
                                    <li><b>Schedule Your Appointment:</b> Easily book your audio or video consultation through our user-friendly online platform.</li>
                                        <li><b>Connect with Our Experts:</b> Join the virtual consultation at your scheduled time.  Our ENT specialists will discuss your concerns, provide advice, and guide you through the next steps.</li>
                                        <li><b>Prescription and Follow-Up:</b> Receive personalized treatment plans and prescriptions digitally. Follow up with our team as needed for a continued partnership in your ear, nose, and throat health.</li>
                                    </ol>
                               
                                <p className='mt-[40px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal '><b>Your ENT Health, Our Priority:</b></p>
                                <p className=' mt-[20px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal '>Dr. Razique ENT Clinic is more than just an ear, nose, and throat clinic; it's a commitment to your ENT well-being. Trust us to provide expert care, leveraging the latest technology to bring the best of ENT services to your doorstep.</p>
                                <p className=' mt-[20px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal '>Schedule your virtual consultation today and embark on a journey to healthier, happier ENT health with Dr. Razique ENT Clinic.</p>
   </>
  }