import MainImg from '../../assets/images/articleRemedies/MainImg.webp'
import Img1 from '../../assets/images/articleRemedies/SectionImg1.webp'
import Img2 from '../../assets/images/articleRemedies/SectionImg2.webp'
import Img3 from '../../assets/images/articleRemedies/SectionImg3.webp'

export const postDetails={
    img:MainImg,
    title:"5 Home Remedies To Ease Your Jaw Pain And Find Relief",
    views:"45K",
    date:"OCTOBER 20, 2023",
    text:<>
          <p>The temporomandibular joints, often shortened to just TMJ, are two of the most complex joints in the body and serve to connect the skull 
            and the lower jaw. It is also estimated that as much as 12% of adults today experience some degree of TMJ disorder or jaw pain that can arise
             out of the blue and spread to other parts of the neck and head. Thankfully, this condition is mostly temporary and goes away on its own with 
             the help of simple home remedies. Below, we go over why your TMJ might hurt and how to relieve the pain.</p>
          <b>Signs and symptoms of TMJ disorders</b>
          <p>Understanding how the jaw works is key to learning why your TMJ might hurt all of a sudden. Essentially, there are two of these 
            temporomandibular joints that function similarly to the hinges found in doors and work by closing the lower jaw against the upper teeth. When 
            the joints, teeth, and other jaw muscles work harmoniously, jaw problems are less likely to occur.</p>
          <p>The mouth’s opening and closing motion happens constantly as we talk and eat throughout the day, but the teeth rarely make 
            contact with each other. Once that is no longer the case is when problems can arise. Clenching your teeth or grinding them together 
            puts additional stress on the TMJ and takes a toll on it over time, and this often takes place when you are asleep. Hence, TMJ pain is
             a type of injury that stems from overuse. Besides jaw pain, other symptoms of TMJ issues can manifest as:</p>
                                        <p>
              </p><ol  className=" ml-4 list-disc">
                    <li>Facial Pain</li>
                    <li>Clicking Or Popping Noises In The Jaw</li>
                    <li>Wear Or New Fractures On Your Teeth</li>
                    <li>Limited Range Of Mobility Or Pain When Opening The Jaw</li>
                    <li>Headaches</li>
                    <li>Dizziness</li>
                    <li>Tinnitus</li>
                    </ol>
                <p></p>
                <p>Smoking, alcohol use, stress, and certain medications can all increase your risk of developing TMJ problems.</p>
                <b>Tips to relieve TMJ pain at home</b>
                <b>1. Try stretching your jaw</b>
                <p>Just like with the other muscles in your body, a good stretching session may help relieve your jaw pain. First, raise your 
                    tongue and put it against the roof of your mouth. Next, open your mouth as wide as possible while maintaining your tongue’s 
                    previous position. Maintaining contact with the roof of the mouth ensures you do not open your jaw too wide. Keep your mouth
                     and tongue in this position for six seconds before closing it. Repeat this opening and closing stretch for six times. This exercise 
                     stretches any tight jaw muscles you may have and relieves their pain-inducing contractions.</p>
                <b>2. Apply hot and cold packs</b>
                <p>Warm and cold compresses can help relieve jaw pain in different ways. The latter’s numbing effect is ideal for 
                    relieving a sudden onset of sharp jaw pain, often indicating inflammation, as it numbs the affected joints and 
                    reduces swelling. For chronic TMJ pain that continues for several days, switch to a warm compress and apply it at
                     least four times daily.</p>
                <b>3. Massage your jaw muscles</b>
                <p>A simple massage may prove beneficial in releasing tension in the myofascial trigger point of 
                    your aching jaw muscles as it brings neuromuscular harmony among the TMJ, neck muscles, and tooth contact. 
                    For best results, apply a warm compress when performing the massage.</p>
                <b>4. Avoid chewy foods</b>
                <p>Naturally, you should avoid crunchy or chewy foods when your TMJ is hurting since they will aggravate the pain.
                     Examples of foods to steer clear of for the time being include:</p>
                        <p></p><ol className="list-decimal ml-4">
                        <li>Tough Meats Like Steaks</li>
                        <li>Nuts</li>
                        <li>Jawbreaker Candies And Other Hard Sweets</li>
                        <li>Tough Fruits And Vegetables Like Carrots, Apples, And Celery</li>
                        </ol>
                        <p></p>
                    <b>5. Sleep on your back</b>
            <p>Maintaining good posture while asleep is just as important as when you are awake. 
                The best position to sleep in for perfect posture is to lie on your back, as it keeps your head,
                jaw, and neck in their natural alignment and keeps your TMJ free from strain for the entire night.</p>
            <b>Conclusion</b>
            <p>The following tips above are generally enough to relieve TMJ issues. If they are not enough to relieve the pain, consider 
                speaking to a healthcare provider for an early diagnosis and treatment to prevent your TMJ pain from becoming a chronic condition.</p>
        <p>With the MYCLNQ app, getting access to medical help has never been easier. In just a few clicks, you can access many essential 
            services such as private ambulance transport, pharmaceutical e-commerce, and virtual ART tests in Singapore anytime, anywhere.
             For more information about our services or to understand more about the symptoms you are experiencing, like bruising easily,
              please don’t hesitate to contact us today.</p>
        <p>Refence: <a href="https://bsos.org.uk/for-patients/self-help/" target="_blank" rel="noopener noreferrer">
            British Society Of Occlusal Studies</a></p>
                                    
    </>,
}


export const recentPost=[
    {
        id:1,
        img:Img1,
        heading:"Change In Eye Colour And Why It Warrants Medical Attention",
        date:"Nov 9, 2023",
        link:"/articleEye",
        border:true
    },
    {
        id:2,
        img:Img2,
        heading:"Treating Chronic Migraines: What To Do Next If Nothing Helps",
        date:"Oct 26, 2023",
        link:"/articleMigrane",
        border:true
    },
    {
        id:3,
        img:Img3,
        heading:"SEEING THIN RED LINES UNDER YOUR NAILS? IS IT ENDOCARDITIS?",
        date:"Sep 27, 2023",
        link:"/articleEndoCarditis",
        border:false
    },
    ]