import React,{useState,useEffect} from 'react';
import EventImage1 from '../assets/images/Events/EventImage1.webp';
import icon from '../assets/images/logo-rbg.png';

function Events() {
	const [isMediumScreen, setIsMediumScreen] = useState(false);


	useEffect(() => {
        const handleResize = () => {
          setIsMediumScreen(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };
    
        // Initial check on mount
        handleResize();
    
        // Event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Cleanup on unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
        }, []);


	return (
		<>
			<section className='w-full md:h-[515px]  h-[590px] relative bg-fixed z-[-50]   bg-backgroundAsh' style={{
				backgroundImage: `url(${EventImage1})`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
			}}>
					<div className=' md:h-[300px] md:w-[850px] md:m-3 m-0 relative  h-[450px] ' >           {/*border-2 border-black*/}
						<div className='relative md:top-16 md:left-8 lg:p-10 p-2 '><h1 className='text-[24px] md:text-[30px] mx-2 md:leading-[38px]  leading-[40px]  text-black font-semibold '> Events Organized by <span className='text-reddish' ><br />MyCLNQ Health India</span> </h1>
							<div className='lg:p-10 md:p-6 p-2 '><p className='font-normal  text-[#2C2D3F] text-[1rem] leading-[24px]'>MyCLNQ Health is regularly serving around people to provide accessible and affordable health care services. <br />
								We are offering economical health care packages including diagnostic services and Drs consultation/Opinios in affordable price to provide quality healthcare services from our qualified healthcare professionals.</p>
							</div>
						</div>
						{ isMediumScreen&&(<div>
						<img src={icon} alt='Icon'  />
					</div>)}

					</div>
				


			</section>


		</>
	)
}

export default Events