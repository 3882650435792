import React from 'react'
import sectionImg from '../assets/images/section-img.png'
import aboutImg from '../assets/images/aboutDoc.webp'


const AboutMain = () => {
  return (
    <section className='xl:max-h-[770px] w-full max-h-[1120px]'>  
    <div className='flex flex-col justify-center items-center '>
        <div className=' w-full pt-[48px]  h-[169px] xl:h-[220px] 
        xl:px-[250px] lg:mb-[60px] mb-0 text-center flex flex-col items-center justify-center '>
            <h2 className=' xl:mb-6 text-[#2C2D3F]  mb-[10px] text-[22px] xl:text-[32px] font-semibold capitalize leading-tight text-center'>
            Enabling Digital Health Ecosystem. Serving Community</h2>
            <img className=' text-center' src={sectionImg} alt='doctors' loading='lazy' />
        </div>
        <div className='lg:h-[550px] h-[903px]   mx-[48px] py-[48px] flex flex-col items-center justify-center lg:flex-row '>
        <div className="  lg:h-[550px] h-[583px] lg:w-[680px] w-[343px] px-[15px]">
        <h2 className="text-reddish font-medium text-[2rem] leading-[1.2]">About MyCLNQ Health</h2>
        <p className="my-[1rem] text-[#757575] leading-6 font-normal text-sm">
          MyCLNQ Health is a Singapore-based Health Tech company, focused on
          connected health also known as technology-enabled care (TEC). The
          company is successfully running a connected health ecosystem called
          “MyCLNQ” which is a one-stop solution for all family healthcare needs.
        </p>
        <p className="my-[1rem] text-[#757575] leading-6 font-normal text-sm"> 
        The AI-enabled mobile app focuses on telemedicine, mHealth, eHealth
            services. The company believes in providing affordable healthcare and
            an age-friendly environment for patient care.</p>
        <div className="my-[1rem]">
            <h4 className="text-[1.5rem] text-reddish font-medium leading-[1.2]">Our Mission</h4>
            <p className="mb-[16px] text-[#757575] leading-6 font-normal text-sm ">Smart Choices In Digital Health Beyond Hospitals &amp; Clinics.</p>
            <h4 className="text-[1.5rem] text-reddish font-medium leading-[1.2]">Our Vision</h4>
            <p className='mb-[16px] text-[#757575] leading-6 font-normal text-sm '>Enabling Digital Health Ecosystem. Serving Community</p>
        </div>
      </div>
         <div className=" lg:h-[550px] h-[208px] px-[15px] lg:mt-0 mt-8 lg:w-[711px] w-[343px]">
						<div className="  shadow-[0px_0px_10px_#888888]">
							<img src={aboutImg} alt="contactImg" loading='lazy'/>
						</div>
					</div>
        </div>
    </div>
</section>
  )
}

export default AboutMain