import React from 'react'
import sectionImg from '../assets/images/section-img.png'

const FAQMain = () => {
  return (
    <section className='xl:h-[1177px] py-12 md:max-h-[1343px] max-h-[2360px] w-full flex flex-col justify-center items-center '>  

        <div className=' xl:w-[1519px] md:w-[798px] w-[335px] px-[20px]  h-[161px] xl:h-[211px] 
        xl:px-[250px] mb-[60px] text-center flex flex-col items-center justify-center '>
            <h2 className=' xl:mb-6 text-[#2C2D3F]  mb-[10px] text-[22px] xl:text-[32px] font-semibold capitalize leading-tight text-center'>
            Optimal care within reach.</h2>
            <img className=' text-center' src={sectionImg} alt='doctors'  loading='lazy'/>
            <p className=' md:text-[15px] text-[13px] text-[#888] mt-[15px] leading-6 font-normal'>
            Get in touch for personalized care, addressing your unique health needs comprehensively.</p>
        </div>
        <div className='lg:h-[870px] md:h-[1062px] shadow-[0_0_10px_1px_#8888884e] px-[15px] py-[16px] h-[2084px] md:w-[768px] w-[375px]  lg:w-[1140px] text-base mx-auto  bg-white '>
              <h2 className="text-center font-bold text-[#2C2D3F] text-[2rem] leading-tight" >Frequently Asked Questions</h2>
              <p className="my-4 mb-6 text-center  text-[#757575] leading-6 font-normal text-base">Here’s a complete list of all the questions asked and answered 
                    by the experts at MyCLNQ about everything you need to know before you find your healthcare professional online. 
                    Read on to find out more about our services.</p>
              <h5 className="text-reddish font-medium text-[1.25rem] leading-tight">What medical conditions can a virtual doctor effectively treat?</h5>
              <p className="my-2 mb-6   text-[#757575] leading-6 font-normal text-base">Virtual doctors can provide remote care for common illnesses and minor ailments 
              such as cold and flu symptoms, allergies, urinary tract infections (UTIs), skin rashes, and respiratory infections. 
              Additionally, virtual doctors can offer ongoing management and consultations for chronic conditions like diabetes, 
              hypertension, asthma, and mental health conditions.</p>
              <h5 className="text-reddish font-medium text-[1.25rem] leading-tight">Can a virtual doctor provide prescriptions for medications?</h5>
              <p className="my-2 mb-6   text-[#757575] leading-6 font-normal text-base">Yes, virtual doctors have the authority to prescribe medications in many cases. 
              During a virtual consultation, the doctor can evaluate your symptoms, diagnose your condition, and determine the 
              appropriate treatment plan. If medication is necessary, the virtual doctor can electronically send a prescription 
              to a pharmacy of your choice for fulfillment.</p>
              <h5 className="text-reddish font-medium text-[1.25rem] leading-tight">How do online doctors diagnose medical conditions?</h5>
              <p className="my-2 mb-6 text-[#757575] leading-6 font-normal text-base">Online doctors employ various techniques to diagnose medical conditions remotely. They typically 
              rely on a combination of patient-reported symptoms, medical history, and digital tools such as video consultations, 
              photos, and medical questionnaires. Through detailed discussions and virtual examinations, online doctors can gather 
              sufficient information to make informed diagnoses and provide appropriate treatment recommendations.</p>
              <h5 className="text-reddish font-medium text-[1.25rem] leading-tight">Is the quality of care from online doctors comparable to in-person visits?</h5>
              <p className="my-2 mb-6 text-[#757575] leading-6 font-normal text-base">Yes, online doctors strive to provide the same level of quality care as in-person visits. 
              While they may not be able to conduct physical examinations, online doctors leverage advanced technology, medical expertise, 
              and patient collaboration to deliver accurate diagnoses, personalised treatment plans, and timely medical advice. 
              It’s important to choose reputable online platforms and ensure that the online doctors you consult are licensed and
              experienced in their respective fields.</p>
              <h5 className="text-reddish font-medium text-[1.25rem] leading-tight">How can I trust the reliability and qualifications of caregivers found through the MyCLNQ?</h5>
              <p className="my-2 mb-6 text-[#757575] leading-6 font-normal text-base">MYCLNQ takes the reliability and qualifications of caregivers seriously. We have a 
              rigorous screening process in place to ensure that caregivers on our platform meet the highest standards. 
              This includes verifying their credentials, conducting thorough background checks, and reviewing their experience 
              and qualifications. We also encourage user reviews and ratings, providing transparency and accountability in the caregiver selection process.</p>
			
        </div>
    
</section>
  )
}

export default FAQMain