import React from 'react'
import img from "../assets/images/legal/legal-new.png"
import pdf1 from '../assets/doc/MGT-7-Website-2021-22.pdf'
import pdf2 from '../assets/doc/MGT-7-Website-2020-21.pdf'
import pdf3 from '../assets/doc/MGT-7-Website.pdf'
import pdf4 from '../assets/doc/Myclnq _MGT-7-2023.pdf';



const LegalMain = () => {
  return (
    <section className=' lg:h-[520px] md:h-[475px] h-[400px] bg-[#fff] w-full'>
      <div className=' flex flex-col md:justify-between md:gap-3  justify-center items-center w-full lg:h-[520px] md:h-[440px] h-[400px]'>
        <div className='lg:h-[323px] md:h-[202px] h-[200px] bg-[#fff] w-full'>
            <img src={img} alt="legal" className=' object-contain lg:h-[323px] md:h-[202px] h-[200px]' loading='lazy'/>
        </div>
        <div className='lg:max-h-[156px] md::max-h-[156px] nax-h-[200px] bg-[#fff] mt-[12px]'>
        <div className=" mx-auto lg:max-h-[156px] md::max-h-[156px] nax-h-[200px] px-[15px] lg:w-[1140px] ">
                <header>
                  <h3 className='font-medium text-[#2C2D3F] text-[1.75rem]'>Legal Compliances</h3>
                </header>
                <div className="flex flex-wrap mx-[-15px]">
                    <div className="px-[15px] w-[380px] h-[120px]">
                    <br/>
                    <a className="text-sm leading-[24px] font-normal text-[#888] text-reddish
                    duration-500 hover:text-reddish " href={pdf4} target="_blank" rel='noreferrer'>MGT 7 of 2022-23</a><br/>

                    <a className="text-sm leading-[24px] font-normal text-[#888]  text-reddish
                    duration-500 hover:text-reddish " href={pdf1} target="_blank" rel='noreferrer'>MGT 7 of 2021-22</a><br/>
                    <a className="text-sm leading-[24px] font-normal text-[#888] text-reddish
                    duration-500 hover:text-reddish  " href={pdf2} target="_blank"  rel='noreferrer'>MGT 7 of 2020-21</a><br/>
                    <a className="text-sm leading-[24px] font-normal text-[#888] text-reddish
                    duration-500 hover:text-reddish " href={pdf3} target="_blank"  rel='noreferrer'>MGT 7 of 2019-20</a><br/><br/>
                    </div>
                    
                </div>
            </div>
        </div>
        </div>
    </section>
  )
}

export default LegalMain