import React from 'react'

const Button = ({text ,width,handleClick}) => {
  return (
    <button className={`relative ${width}
     overflow-hidden bg-reddish text py-[13px] px-[25px]  font-medium capitalize rounded-[4px] text-[14px]
          text-white transition-all before:absolute before:left-0 before:top-0 before:h-full before:w-0 before:duration-500
           after:absolute after:right-0 after:top-0 after:h-full after:w-0 after:duration-500   hover:before:w-2/4
           hover:before:bg-[#2C2D3F] hover:after:w-2/4 hover:after:bg-[#2C2D3F]`}
           onClick={handleClick}
           ><span className="relative z-10">{text}</span></button>
  )
}

export default Button