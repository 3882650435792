import MainImg from '../../assets/images/newsAi/qsehp.AnwarRafique2.webp'
import Img1 from '../../assets/images/newsAi/SectionImg1.webp'
import Img2 from '../../assets/images/newsAi/SectionImg2.webp'
import Img3 from '../../assets/images/newsAi/SectionImg3.webp'
import bgImg from '../../assets/images/newsAi/blockqoute-bg.webp'

export const postDetails={
    img:MainImg,
    title:"Evolving Ecosystems In Healthcare: A Look Into AI-Powered Solutions",
    views:"23K",
    date:"NOVEMBER 28, 2023",
    text:<>
    <p className='mb-[20px] text-sm leading-[24px]'>Anwar Rafique, Co-Founder &amp; CEO, MyCLNQ Health Singapore</p>
    <p className='mb-[20px] text-sm leading-[24px]'>Anwar is a seasoned business professional with close to two 
    decades of experience across diverse verticals. Prior to co-founding MyCLNQ, he has successfully 
    handled key roles across companies that include Citibank, Merrill Lynch, 
    Prudential Asset Management (Singapore), Société Générale, Wolters Kluwer Financial 
    Services and CIMB, to name a few. Additionally, he is currently also the Mentor at International 
    Trade Council. In a recent conversation with Ananth V (Senior Editor, CEO Insights Asia), 
    Anwar shared his insights on various aspects pertaining AI implementation in healthcare. 
    Below are a few key extracts from the exclusive interview –</p>    
   <p className='mb-[20px] text-sm leading-[24px]'>AI is revolutionizing the healthcare ecosystems in many ways – be it enhancing patient care, optimizing 
   operations, or even medical research transformation. AI is also enabling continuous monitoring of patients with chronic health conditions 
   by alerting the doctors on the patients’ vitals. Telemedicine is another tech advancement that has gained widespread adoption since the covid 
   pandemic and continues to redefine the way healthcare is being consumed. As part of telemedicine, AI integrated virtual chatbots acted as virtual 
   nurses by giving patients basic medical information, helping book consultations, sending automatic reminders for medicine intake, and many more.</p>
    <b>How is artificial intelligence currently impacting the healthcare ecosystem globally?</b>
    <p className='mb-[20px] text-sm leading-[24px]'>AI is revolutionizing the healthcare ecosystems in many ways – be it 
    <b>enhancing patient care,</b> optimizing operations, or even <b>medical research transformation.</b> AI is also enabling 
    <b>continuous monitoring of patients</b> with chronic health conditions by alerting the doctors on the patients’ vitals. 
    Telemedicine is another tech advancement that has gained widespread adoption since the covid pandemic and continues to redefine the way healthcare 
    is being consumed.As part of telemedicine, AI integrated virtual chatbots acted as virtual nurses by giving patients basic medical information,
     helping book consultations, sending automatic reminders for medicine intake, and many more.</p>
     <b>Briefly explain the role of human intervention while implementing automation technologies such as AI in healthcare.</b>
    <p  className='mb-[20px] text-sm leading-[24px]'>
    In terms of advanced care, reports that are acquired by leveraging AI, in no doubt have the need to be analyzed by doctors. 
    The same goes with robotic surgeries, wherein doctors need to pre-program every parameter to ensure that the entire process is 
    remotely driven by humans. Even for personalized medicine, post results from the AI enabled systems; the medicine has to be manually 
    dispensed by doctors, nurses or healthcare personnel. To summarize, AI only plays the role of a facilitator in healthcare, 
    but the execution must be mandatorily taken care by humans, especially in the cases of advanced diseases.
    </p>
    <b>Tell us about the various challenges and ethical considerations that arise while implementing AI in healthcare.</b>
    <p className='mb-[20px] '>
    Every transformation that we implement in the healthcare industry comes with its own set of challenges, raising concerns over ethics, 
    data privacy and others. The same holds good for implementing AI or any other automation technologies as well. Being one of the most 
    common concerns while implementing AI in healthcare, organizations can ensure data privacy by having a robust end-to-end data encryption
     in place, complying with regulatory frameworks such as GDPR & HIPAA, ensuring strict access to data, collect only the necessary data, 
     educate the healthcare personnel and many others. Also, hospitals must build a sense of trust among patients on using AI integrated 
     healthcare systems and ward-off the misconceptions that they might have in this regard. Overall, healthcare professionals, data scientists, 
     policymakers and every other stakeholder involved in the healthcare business 
    must come together and collaborate with each other to successfully tackle the challenges and ethical concerns associated with AI implementation.
    </p>

    <blockquote className=" relative bg-cover bg-center bg-no-repeat p-[30px] overflow-hidden before:absolute mb-4
     before:left-0 before:top-0 before:h-full before:w-full before:bg-reddish before:opacity-[0.8]"  style={{ backgroundImage: `url(${bgImg})` }}>
        <p className=' text-[#fff] leading-[26px] text-[15px] relative'>Mental health and wellness will also start witnessing an increased AI implementation in terms of delivering therapies</p>
    </blockquote>
    <b>Throw some light on the importance of educating healthcare personnel and upgrading 
        infrastructure to leverage the maximum potential of modern day technologies.</b>
        <p className='mb-[20px] '>
        Be it AI or any other technologies that have emerged over the last decade, every individual who is actively engaged 
        in their usage must mandatorily be educated by their respective organizations to reap maximum benefits. Thus, 
        it is important for every organization, that is implementing any of the modern day technologies, to invest rightly in 
        workforce training and updating the existing infrastructure to be compatible with the new technology. 
        Two of the crucial factors in this regard are the commitment of the leadership team and the organization’s overall vision and mission statements.
    </p>
    <b>How do you expect the healthtech landscape in India to evolve in the coming years?</b>
    <p className='mb-[20px] '>Going forward, there is no doubt that AI driven healthcare will continue to evolve rapidly and 
    revolutionize the way healthcare is being offered/consumed. Some of the key areas where AI will have a bigger impact are robotics 
    surgery, remote monitoring, telemedicine, home healthcare, and more. Also, mental health and wellness will start witnessing an 
    increased AI implementation in terms of delivering therapies.</p>
    <p className='mb-[20px]'>Source: <a href="https://www.ceoinsightsasia.com/startups/evolving-ecosystems-in-healthcare-a-look-into-aipowered-solutions-nwid-11261.html" target="_blank" rel="noopener noreferrer">
        https://www.ceoinsightsasia.com/startups/evolving-ecosystems-in-healthcare-a-look-into-aipowered-solutions-nwid-11261.html</a></p>
    </>
}


export const recentPost=[
    {
        id:1,
        img:Img1,
        heading:"Change In Eye Colour And Why It Warrants Medical Attention",
        date:"Nov 9, 2023",
        link:"/articleEye",
        border:true
    },
    {
        id:2,
        img:Img2,
        heading:"Treating Chronic Migraines: What To Do Next If Nothing Helps",
        date:"Oct 26, 2023",
        link:"/articleMigrane",
        border:true
    },
    {
        id:3,
        img:Img3,
        heading:"5 Home Remedies To Ease Your Jaw Pain And Find Relief",
        date:"Oct 20, 2023",
        link:"/articleRemedies",
        border:false
    },
    ]