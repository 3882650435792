import MainImg from '../../assets/images/articleEndocarditis/MainImg.webp'
import Img1 from '../../assets/images/articleEndocarditis/SectionImg1.webp'
import Img2 from '../../assets/images/articleEndocarditis/SectionImg2.webp'
import Img3 from '../../assets/images/articleEndocarditis/SectionImg3.webp'

export const postDetails={
    img:MainImg,
    title:"Seeing Thin Red Lines Under Your Nails? Is It Endocarditis?",
    views:"21K",
    date:"SEPTEMBER 27,  2023 ",
    text:<>
    <p>Thin red or reddish-brown lines under your fingertips can appear
     due to various reasons, such as trauma to the finger and other health conditions, including endocarditis.
     The appearance of these lines has traditionally been a part of the clinical criteria for diagnosing this rare condition.</p>    
   <p>Endocarditis occurs when the heart’s inner lining gets infected and leads to 
   inflammation, which manifests into several flu-like symptoms and affects other parts of the body, like your fingernails.
    Read on to learn more about this rare heart infection and why it is critical to seek medical attention immediately.</p>
    <b>How endocarditis affects your fingernails</b>
    <p>Apart from flu-like symptoms like chills, fever, and nausea, endocarditis may cause. 
    These are thin red lines under your fingertips running in the same direction as the nail grows and resembles splinters, hence their name. 
    These marks indicate that small clots have damaged the capillaries under the nails or the blood vessels have been impaired or swollen.</p>
    <b>What causes endocarditis</b>
    <p>Endocarditis typically stems from a bacteria that finds its way into the body through various means, 
    including a cut in the skin, dental work, or surgery. The bacteria travel through the bloodstream 
    until they reach the heart and may get stuck in the heart lining or latch onto the heart valves and start to grow.</p>
    <p>Small clumps of bacteria could also develop at the infection site and act like blood clots 
    that move through the bloodstream and reduce blood flow to the organs. People are more prone to developing endocarditis 
    if they have a heart valve disease, long-term central venous catheter, or a health condition that weakens their immune system. 
    In addition, you may also increase your risk of endocarditis
     if you have had a previous case of it, have a history of intravenous drug use, and have poor dental hygiene habits.</p>

     <b>The risks of endocarditis-induced splinter haemorrhages</b>
    <p>Splinter haemorrhages alone are not particularly dangerous, but if you see them on your nails, it is important
     to notify your doctor immediately to determine their cause, as they may indicate a serious health concern.</p>
    <p>As per a 2016 research, around one-third of the people with the condition developed thin red lines under 
    their nails. Hence, these haemorrhages traditionally served as one of the bases for diagnosing endocarditis caused by 
    infections as it is one of its telltale symptoms. Hence, with endocarditis being a rare and serious condition with potentially 
    life-threatening consequences,it is best to see a virtual doctor in Singapore if you notice splinter haemorrhages and any of its other symptoms.</p>

    <b>Treatments for endocarditis</b>
    <p>If endocarditis is causing your fingernail haemorrhages, your doctor may prescribe 
    treatment using IV antibiotics. This approach involves performing a blood culture on your blood and
     consulting with infectious disease specialists to determine which antibiotics are best suited to treat your specific case.</p>
    <p>Patients typically undergo six to eight weeks of antibiotic treatments upon 
    receiving an endocarditis diagnosis. This may be accompanied by surgery to replace affected heart valves and 
    implanted materials like defibrillators and pacemakers or if the condition has affected the heart muscles or other body parts.</p>
    
    <b>Conclusion</b>
    <p>
    Splinter haemorrhages under the fingernails are a sign of blood vessel damage, which may be caused by endocarditis that has 
    reached its later stages. Given that this condition could have 
    potentially fatal consequences, it is highly recommended to contact your doctor immediately to prevent it from progressing any further.
    </p>
    <p>
    With the MYCLNQ app, getting in touch with a certified healthcare provider has never been easier. In just a few clicks, 
    you can book an online medical consultation as well as
     access many other key medical services, including private ambulance transport, supervised ART test booking, medical e-commerce, and more.</p>

    <p>Reference: <a href="https://my.clevelandclinic.org/health/diseases/23341-splinter-hemorrhage"   
    target="_blank" rel="noopener noreferrer">Cleveland Clinic</a></p>
    </>,
}


export const recentPost=[
    {
        id:1,
        img:Img1,
        heading:"Change In Eye Colour And Why It Warrants Medical Attention",
        date:"Nov 9, 2023",
        link:"/articleEye",
        border:true
    },
    {
        id:2,
        img:Img2,
        heading:"Treating Chronic Migraines: What To Do Next If Nothing Helps",
        date:"Oct 26, 2023",
        link:"/articleMigrane",
        border:true
    },
    {
        id:3,
        img:Img3,
        heading:"5 Home Remedies To Ease Your Jaw Pain And Find Relief",
        date:"Oct 20, 2023",
        link:"/articleRemedies",
        border:false
    },
    ]