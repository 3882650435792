import React, { useState } from 'react'
import Button from './subComponents/Button'
import { useNavigate } from 'react-router-dom'

const ProFeatures = () => {
    const [show,setShow]=useState(false);
    const navigate=useNavigate();
    const handleShow=()=>{
        setShow(!show)
    }
    const handleClick=()=>{
        navigate('/healthCarePackage')
    }
    
  return (
    <ul className={`fixed  w-[300px] h-auto leading-[46px] text-sm bg-[#fff] 
    text-left text-[#fff] top-[70%] transform translate-y-[-50%] shadow-[-4px_0px_5px_#000000036]
     z-[9999] pt-[20px] pb-[30px] px-[30px] duration-500 ${show?'left-0':'left-[-300px]'} `} onClick={handleShow}>
			<div className=" absolute top-0  left-[18.75rem] w-[11rem] h-[45px]  
       leading-[45px] text-sm rounded-t-0 rounded-r-[5px] rounded-b-[5px] rounded-l-[0px]
               bg-reddish text-center text-[#fff] cursor-pointer shadow-[4px_0px_5px_#00000036]" href='###'>HealthCare Packages</div>
 	<li className=" text-[#333] m-0 p-0 leading-[22px] mb-[10px]">Each package 
  tailored to meet your unique needs and provide comprehensive coverage.</li>
			<div className="button">

				<Button text={'More Details'} width={'w-full flex h-[46px] items-center justify-center'} handleClick={handleClick}/>
			</div>
		</ul>
  )
}

export default ProFeatures