import React from 'react'
import { FaCaretRight, FaFacebook, FaLinkedin, FaXTwitter } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { FaInstagram } from "react-icons/fa6";
import QrCode from "../assets/images/download_app_qr.webp";
import { quickLinkList,productList} from "./Lists/footerList";
import { Link } from 'react-router-dom';


const Footer = () => {
    const currentYear = new Date().getFullYear();
  return (
    <footer className=' w-full '>
        <div className=' bg-reddish2   pt-[40px] pb-[20px]  mt-28'>  {/*mt-28*/}
            <div className=' w-full px-[15px] mx-auto  justify-center
           flex md:items-center lg:items-center  md:flex-row md:flex-nowrap
             flex-wrap flex-col'>
                        <div className='px-[15px] h-[320px] relative w-[285px] flex flex-col items-start'>
                            <h2 className=' text-[#fff] text-xl font-semibold mb-10 pb-5 capitalize'>Quick Links</h2>
                            <span className="absolute w-[50px] right-0 left-[17px] top-[40px] h-[3px]  bg-[#fff]"></span>
                            <nav>
                                <ul className='text-[#fff] text-sm '>
                                    {quickLinkList.map(({id,text,path})=>
                                        <li key={id} className='pb-[20px] group'>
                                            <Link to={path} className=' font-normal flex items-center  gap-[10px]  group-hover:pl-[8px] duration-500  '>
                                                <FaCaretRight />{text}</Link>
                                        </li>
                                    )}    
                                </ul>
                            </nav>
                        </div>
                        <div className='px-[15px] h-[320px] relative w-[285px] flex flex-col items-start'>
                            <h2 className=' text-[#fff] text-xl font-semibold mb-10 pb-5 capitalize'>Products &amp; Services</h2>
                            <span className="absolute w-[50px] right-0 left-[17px] top-[40px] h-[3px]  bg-[#fff]"></span>
                            <nav>
                                <ul className='text-[#fff] text-sm '>
                                    {productList.map(({id,text,path})=>
                                        <li key={id} className='pb-[20px] group'>
                                            <Link to={path} className=' font-normal flex items-center  gap-[10px]  group-hover:pl-[8px] duration-500 '>
                                                <FaCaretRight/>{text}</Link>
                                        </li>
                                    )}    
                                </ul>
                            </nav>
                        </div>
                        <div className='px-[15px] h-[320px] relative w-[285px] flex flex-col items-start'>
                            <h2 className=' text-[#fff] text-xl font-semibold mb-10 pb-5 capitalize'>Get in Touch </h2>
                            <span className="absolute w-[50px] right-0 left-[17px] top-[40px] h-[3px]  bg-[#fff]"></span>
                            <p className=' leading-6 text-[#fff] text-sm font-normal'>Making Healthcare Convenient, Accessible And Affordable For All!</p>
                        <p className="my-2  leading-6 text-[#fff] text-sm font-normal flex items-center content-center gap-2">
                            <IoMdMail/> <a href="mailto:support@myclnq.co.in" >support@myclnq.co.in</a></p>
                       
                            <nav className='mt-[25px]'>
                            <ul className=" text-[#fff] flex items-center justify-between gap-4">
                                 <li className='group'><a href="https://www.instagram.com/myclnqindia/" 
                                 className=' relative w-[34px] h-[34px] p-0 
                                 rounded-full block leading-[34px] border-[1px] text-center 
                                 group-hover:bg-[#fff] group-hover:text-reddish duration-500' target="_blank" rel="noopener noreferrer" >
                                    <FaInstagram className=' absolute left-[9px] top-[8px]'  /></a></li>
                                 <li className='group'>
                                  <a href="https://x.com/myclnqindia"  className=' relative w-[34px] h-[34px] p-0 
                                 rounded-full block leading-[34px] border-[1px] text-center 
                                 group-hover:bg-[#fff] group-hover:text-reddish duration-500' target="_blank" rel="noopener noreferrer" >
                                    <FaXTwitter className=' absolute left-[9px] top-[8px]'/></a></li>
                                 <li className='group'>
                                  <a href="https://www.facebook.com/people/Myclnq-Health-India/61563160937516//"  className=' relative w-[34px] h-[34px] p-0 
                                 rounded-full block leading-[34px] border-[1px] text-center 
                                 group-hover:bg-[#fff] group-hover:text-reddish duration-500' target="_blank" rel="noopener noreferrer"  >
                                    <FaFacebook className=' absolute left-[9px] top-[8px]'/></a></li>
                                 <li className='group' >
                                  <a href="https://www.linkedin.com/company/myclnq-health-india/" className='relative w-[34px] h-[34px] p-0 
                                 rounded-full block leading-[34px] border-[1px] text-center 
                                 group-hover:bg-[#fff] group-hover:text-reddish duration-500'target="_blank" rel="noopener noreferrer">
                                    <FaLinkedin className=' absolute left-[8.5px] top-[8px]'/></a></li>
                             </ul>
                            </nav>
                        </div>
                        <div className='px-[15px] h-[320px] relative w-[285px] flex flex-col items-start'>
                            <h2 className=' text-[#fff] text-xl font-semibold mb-10 pb-5 capitalize'>Get The App </h2>
                            <span className="absolute w-[50px] right-0 left-[17px] top-[40px] h-[3px]  bg-[#fff]"></span>
                            <div className=' leading-6 text-[#fff] text-sm font-normal text-left md:text-[12px] lg:text-[14px]'>
                            <p>
                                MyCLNQ Mobile Apps are available on Apple and Google Stores. 
                                Please scan the below QR code to download&nbsp;the&nbsp;app.
                            </p>
                            </div>
                       
                             <div className='pt-[10px]'> 
                                <Link to="/GetApp" target="_blank" rel="noopener noreferrer">
                                <img src={QrCode} alt="QR CODE" className='md:h-[80px] lg:h-[124px] ' loading='lazy'/>
                                </Link>
                             </div>
                        </div>
            </div>
        </div>
        <div className=' bg-reddish py-[25px] text-center'>
            <div className='leading-6 text-[#fff] text-sm font-normal'>
                <p>©{currentYear} MyCLNQ Health. All Rights Reserved By <Link to='/'className=' underline' >myclnqhealth.in</Link> </p>
            </div>
        </div>
    </footer>
  )
}

export default Footer