import React,{useState ,useContext} from 'react'
import { HiMiniChevronLeft,HiMiniChevronRight } from "react-icons/hi2";
import Button from './subComponents/Button';
import { useNavigate} from 'react-router-dom'
import DataContext from '../hooks/DataContext';

const ClinicDetailsMain = ({slides , doctorDetails, clinicDetails ,isActive}) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [animate, setAnimate] = useState(false);
	const navigate=useNavigate();
	const {handleDoctorDetails } =useContext(DataContext);
	const handleOpenModal = (number) => {
	  handleDoctorDetails(number)
		 navigate('/bookDoctorAppointment');
		 console.log(number)
		 // Navigate to the /booking-details page
	   };


	const prevSlide = () => {
   
		const isFirstSlide = currentIndex === 0;
		const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
		setCurrentIndex(newIndex);
		setAnimate(true);
		
	  };
	
	  const nextSlide = () => {
	  
		const isLastSlide = currentIndex === slides.length - 1;
		const newIndex = isLastSlide ? 0 : currentIndex + 1;
		setCurrentIndex(newIndex);
		setAnimate(true);
	  };
	  const transitionStyles = {
		transition: 'background-image 0.5s ease-in-out',
		animation: animate ? 'fadeInOut 0.5s ease-in-out' : 'none',
	  };
	  const bannerStyle = {
		backgroundImage: `url(${slides[currentIndex].url})`,
		...transitionStyles,
	  };
	  const onAnimationEnd = () => {
		setAnimate(false);
	  };

  return (
    <section className="section bg-[#F9F9F9] py-[100px]">
			<div className="container px-[15px] mx-auto">
					<div className=" lg:px-[78px] md:px-[35px] p-0 relative">
			            <div>
                       
						<div className="container md:h-[438px] relative h-[162px] bg-center bg-cover transition-all duration-500 object-contain"  style={bannerStyle}
						onAnimationEnd={onAnimationEnd}
						>
						{isActive&&<p className={` absolute ${ animate ? ' animate-animate-banner' : ''} text-white bottom-[42%]  md:text-[1.5rem] right-0 md:w-[50%] text-[0.8rem] w-full text-center  font-semibold p-[10px] m-0`}>{slides[currentIndex].text1} <br/>{slides[currentIndex].text2}</p>}
						</div>
						
							  {/* Left Arrow */}
							  <div className=' absolute lg:top-[14%]  top-[99px] left-[17px] md:left-[66px] -translate-x-0 
        translate-y-[-50%] lg:left-[109px]  rounded-full w-[50px] h-[50px]  md:text-2xl text-lg md:top-[230px]
          text-reddish bg-white cursor-pointer leading-[50px] hover:text-white hover:bg-reddish'>
          <HiMiniChevronLeft onClick={prevSlide} size={50}  />
        </div>
  {/* Right Arrow */}
        <div className=' absolute  lg:top-[14%] md:top-[230px] right-[17px] md:right-[66px]  top-[99px]  w-[50px] h-[50px]
          -translate-x-0 translate-y-[-50%] lg:right-[109px] md:text-2xl text-lg leading-[50px] rounded-full
		  text-reddish bg-white cursor-pointer hover:text-white hover:bg-reddish '>
            <HiMiniChevronRight onClick={nextSlide} size={50}/>
        </div>
							<div className="background: #FF3C3C; bg-reddish block p-[20px] text-center rounded-none m-0 mt-[-1px]">
								<ul className='flex justify-center items-center md:flex-row flex-col'>
									<li className=' text-[16px] text-[#fff] inline-block md:mr-[60px]'><span 
									className=' font-medium inline-block mr-[5px]'>Doctor:</span>{doctorDetails.name}  </li>
									<li className='text-[16px] text-[#fff] inline-block md:mr-[60px]'>
										<span className=' font-medium inline-block mr-[5px]'>Speciality:</span> {doctorDetails.speciality} </li>
									<li className='text-[16px] text-[#fff] inline-block md:mr-[60px]'>
										<span className=' font-medium inline-block mr-[5px]'>Phone No:</span>{doctorDetails.phoneNo}</li>
								</ul>
							</div>
							<div className="body-text">
								<h3 className=' md:text-[30px]  text-[24px] font-semibold text-[#333] mt-[40px] leading-[1.2] '>{clinicDetails.heading}</h3>

                                <div className=' text-[14px] text-[#757575] m-0 leading-[24px] font-normal'>
                                    <p className='mt-[20px]'><b>CLINIC DETAILS</b></p>
                                    <p className="mt-0"><b>Doctor: </b>{clinicDetails.docName}</p>
                                    <p className="mt-0"><b>Qualification: </b>{clinicDetails.qualification}</p>
                                    <p className="mt-0"><b>Speciality: </b>{clinicDetails.speciality} </p>
                                    <p className="mt-0"><b>Phone Number: </b>{clinicDetails.phoneNum}</p>
                                    <p className="mt-0"><b>Email: </b><a href={`mailto:${clinicDetails.email}`}>{clinicDetails.email}</a></p>
                                    <p className="mt-0"><b>Work Expirence: </b>{clinicDetails.exp}</p>
                                    <p className="mt-0"><b>Languages: </b>{clinicDetails.languages}</p>
                                    <p className="mt-0"><b>Clinic Address: </b>{clinicDetails.clinicAddress}</p>
                                    <p className="mt-0"><b>Clinic Timings: </b>{clinicDetails.clinicTimings}</p>
                                    </div>
                                   {clinicDetails.details}
                                <div className="button mt-3">
                                    <Button text={'Book Appointment'} handleClick={()=>handleOpenModal(Number(clinicDetails.doctorBookingNum))}/>
                                </div>
							</div>
						</div>
					</div>
				</div>

		</section>
  )
}

export default ClinicDetailsMain