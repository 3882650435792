import React from 'react'
import Nav from '../Components/Nav'
import Footer from '../Components/Footer'
import { table2Data ,table3Data} from '../Components/Lists/healthCarePackageTable'
import GoToTop from '../Components/GoToTop'
import HealthCarePackageMain from '../Components/subComponents/HealthCarePackageMain'

const HealthCarePackage = () => {

  return (
    <>
        <Nav/>
        <HealthCarePackageMain table3Data={table3Data} table2Data={table2Data} isActive={false}/>
        <Footer/>
        <GoToTop/>
    </>
  )
}

export default HealthCarePackage