import slide1 from '../../assets/images/clinicDetails/mindVisionClinic/neurology-1.webp'
import slide2 from '../../assets/images/clinicDetails/mindVisionClinic/neurology-2.webp'
import slide3 from '../../assets/images/clinicDetails/mindVisionClinic/neurology-3.webp'

export const slides = [
    {
      url: slide1,
      text1:'Neuro Specialist',
      text2:'Gurgoan'
    },
    {
      url: slide2,
      text1:'Best Neuro Physician',
      text2: 'Green Park Delhi'
    },
    {
      url: slide3,
      text1:'Best Neurologist',
      text2:'Green Park Delhi'
    },
  ];
  export const isActive=true;
  export const doctorDetails={
      name:'Dr. Bhaskar Shukla',
      speciality:' Neurology',
      phoneNo:'8368225762'
  }

  export const clinicDetails={
   heading:'Welcome to Mind Vision Neurology Clinic - Nurturing Minds, Transforming Lives',
   docName:'Dr Bhaskar Shukla',
   qualification:'MBBS, MD',
   speciality:'Neurology',
   phoneNum:'8368225762',
   email:'yogesh.v.s1997@gmail.com',
   exp:'15+ years',
   languages:'Hindi and English',
   clinicAddress: 'E 12, Green park main (near Central Bank), New Delhi, 110016',
   clinicTimings: 'Mon - Sat: 05:00 PM - 09:00 PM,Sunday: Closed',
   doctorBookingNum:2,
   details:<>
       <p className='mt-[20px]  text-[14px] text-[#757575] m-0 leading-[24px] font-normal'>Mind Vision Neurology Clinic is your dedicated partner in comprehensive neurological care, offering audio and video consultations for accessible and personalized services across India.</p>
                                    <p className=' mt-[20px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal'>
                                        Dr. Bhaskar did his MBBS from Nilratan Sircar Medical College, Kolkata in 2005. He did MD Pathology from the prestigious All India Institute of Medical Sciences, New Delhi in the year 2009. Subsequently, he did MD Paediatrics from J.N. Medical College, Aligarh Muslim University in 2014 and went on to do his super specialization in neurology (DM Neurology) from the prestigious King George’s Medical University, Lucknow in 2020.
                                    </p>
                                    <p className=' mt-[20px]  text-[14px] text-[#757575] m-0 leading-[24px] font-normal'>Dr. Bhaskar has a special interest in Movement Disorders, Epilepsy and Paediatric Neurology. He has many publications to his credit in reputed national and international journals.</p>
                                <p className=' mt-[20px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal '>How It Works:
                                    </p><ol className="ml-3 list-decimal  text-[14px] text-[#757575] m-0 leading-[24px] font-normal">
                                        <li><b>Easy Appointment Booking:</b> Schedule your audio or video consultation effortlessly through our user-friendly online platform.</li>
                                        <li><b>Connect with Neurology Experts:</b> Join your virtual consultation at the scheduled time. Our neurologists will discuss your concerns, provide insights, and guide you through the next steps.</li>
                                        <li><b>Personalized Care Plans:</b> Receive personalized treatment plans and recommendations digitally. Count on us for ongoing support and follow-ups to optimize your neurological health</li>
                                    </ol>
                               
                                <p className='mt-[40px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal '><b>Nurturing Minds, Transforming Lives:</b></p>
                                <p className=' mt-[20px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal '>Mind Vision Neurology Clinic is not just a medical facility; it's a commitment to nurturing minds and transforming lives. Let us be your partners in neurological well-being, leveraging technology to bring the best of neurology to your doorstep.</p>
                                <p className=' mt-[20px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal '>Embark on a journey to optimal neurological health with Mind Vision Neurology Clinic. Schedule your virtual consultation today.</p>
   </>
  }