import slide1 from '../../assets/images/clinicDetails/mediaClubIndiaClinic/shrey-jain-gurugram.webp'
import slide2 from '../../assets/images/clinicDetails/mediaClubIndiaClinic/dr-shrey-jain-gurgaon.webp'


export const slides = [
    {
      url: slide1,
    },
    {
      url: slide2,
    },
  ];
  export const isActive=false;
  export const doctorDetails={
      name:'Dr. Shrey Jain',
      speciality:'Urologist',
      phoneNo:'9565933996'
  }

  export const clinicDetails={
   heading:'Welcome to Medi Club India - Your Gateway to Comprehensive Urological Care',
   docName:'Dr. Shrey Jain',
   qualification:'MBBS, MS, MCH (Urology)',
   speciality:'Urologist',
   phoneNum:'9565933996',
   email:'shrey.jain@fortishealthcare.com',
   exp:'13+ years',
   languages:'Hindi and English',
   clinicAddress: '2003, SP, Sector 45, Gurgaon, Haryana, 122022',
   clinicTimings: 'Mon - Sat: 06:00 PM - 08:30 PM ,Sunday: Closed',
   doctorBookingNum:3,
   details:<>
       <p className='mt-[20px]  text-[14px] text-[#757575] m-0 leading-[24px] font-normal'>Dr. Shrey Jain, MBBS, MS, Mch Urolgy, Robotic and Renal transplant surgeon is a highly qualified and experienced urologist in Delhi NCR region.</p>
       <p className='mt-[20px]  text-[14px] text-[#757575] m-0 leading-[24px] font-normal'>He is a consultant in Urology and Kidney Transplant department at Fortis Memorial Research Institute.</p>
       <p className='mt-[20px]  text-[14px] text-[#757575] m-0 leading-[24px] font-normal'>Dr. Shrey has been associated with many prestigious government organizations and completed his education from top medical colleges in India like King George Medical University (KGMU) Lucknow from where he passed his post graduation in general surgery and Sanjay Gandhi Post Graduate Institute, (SGPGI) Lucknow from where he completed his superspecialization in urology.</p>
       <p className='mt-[20px]  text-[14px] text-[#757575] m-0 leading-[24px] font-normal'>He is easily approachable, very polite in nature and gives priority to his patients over anything else. Having done his superspecialization from one of the leading urology institutes and is well versed with all the latest treatments in urology including robotic surgery and renal transplant. He has a keen interest in urology cancers (kidney, bladder, prostate, testis and penile) and endourology dealing with kidney stones, urethral strictures and bladder tumors. He takes keen interest in educational activities and research.</p>
                                  
    <p className=' mt-[20px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal '>Why Choose Medi Club India for Urology:
                                    </p>
                            <ol className="ml-3 list-decimal  text-[14px] text-[#757575] m-0 leading-[24px] font-normal">
                                        <li><b>Virtual Urology Consultations:</b> Experience expert urological care from the comfort of your home. Our audio and video consultations ensure that geographical barriers do not hinder your access to top-notch urology services.</li>
                                        <li><b>Renowned Urologists:</b> Our team consists of highly skilled and renowned urologists dedicated to delivering precise diagnoses and personalized treatment plans for various urological conditions.</li>
                                        <li><b>Comprehensive Urological Solutions:</b> From common urological issues to complex conditions, we offer a comprehensive range of services to address your unique needs. Trust us for expertise in kidney stones, prostate health, urinary tract infections, and more.</li>
                                        <li><b>Patient-Centric Care:</b> At Medi Club India, we prioritize your comfort and well-being. Our patient-centric approach ensures that you receive compassionate and thorough care throughout your urological journey.</li>
                                        <li><b>Cutting-Edge Urological Technology:</b> We stay at the forefront of urological advancements, incorporating state-of-the-art technology to ensure accurate diagnostics and effective treatment strategies.</li>
                                    
                                    </ol>
                                    <p className=' mt-[20px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal '>How It Works:
                                    </p>
                                    <ol class="ml-3 list-decimal  text-[14px] text-[#757575] m-0 leading-[24px] font-normal">
                                        <li><b>Effortless Appointment Booking:</b> Schedule your audio or video consultation seamlessly through our user-friendly online platform.</li>
                                        <li><b>Connect with Urology Specialists:</b> Join your virtual consultation at the appointed time. Our urologists will discuss your concerns, provide expert advice, and guide you through the next steps.</li>
                                        <li><b>Personalized Urological Care Plans:</b> Receive digitally accessible treatment plans and recommendations. Count on us for ongoing support and follow-ups to optimize your urological health.</li>
                                    </ol>
                               
                                <p className='mt-[40px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal '><b>Your Health, Our Priority:</b></p>
                                <p className=' mt-[20px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal '>Medi Club India is not just a healthcare provider; it's a commitment to prioritizing your health and well-being. Let us be your partners in urological care, utilizing technology to bring specialized expertise to your doorstep.</p>
                                <p className=' mt-[20px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal '>Embark on a journey to optimal urological health with Medi Club India. Schedule your virtual consultation with our urology specialists today.</p>
   </>
  }