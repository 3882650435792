import React from 'react'
import { FaLinkedin } from "react-icons/fa6";
import {managementDetails} from "./Lists/managementList"
import sectionImg from '../assets/images/section-img.png'


const Management = () => {
 
  return (
    <section className='bg-[#fff] w-full flex justify-center items-center flex-col my-8' id='Management' >
        <h2 className=" text-reddish  mt-12 mb-4 text-[2rem] leading-[1.2]  capitalize font-bold text-center" >Our Management Team</h2>
        <img className='mb-8' src={sectionImg} alt="section" loading="lazy" />
        {managementDetails.map(({id,authorImg,name,designation,text,link})=>
          <div className="flex flex-col lg:flex-row justify-center lg:mx-[48px] mb-[50px]" key={id}>
          <div className="h-[256px] lg:w-[237px] w-[375px] px-[15px] flex flex-col justify-center items-center">
              <p className="text-center">
                  <img className=" h-[12rem] rounded-[0.25rem]"  src={authorImg} alt="author1"/>
              </p>
              <p className="text-center mt-4 text-[#757575] leading-[24px] font-normal text-sm"><b className=" text-black">
                {name}</b></p>
              <p className="text-center  text-[#757575] font-normal text-sm">{designation}</p>
          </div>
          <div className="lg:w-[830px] w-[375px] px-[15px] flex flex-col">
              {text}
              <div>
                  <a href={link} target="_blank" rel="noopener noreferrer"> 
                  <FaLinkedin  className='text-[2rem] text-[#0a66c2]'/>
                  </a>
              </div>
          </div>
      </div>
        )}
      

    </section>
  )
}

export default Management