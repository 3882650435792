import MainImg from '../../assets/images/articleEye/MainImg.webp'
import Img1 from '../../assets/images/articleEye/SectionImg1.webp'
import Img2 from '../../assets/images/articleEye/SectionImg2.webp'
import Img3 from '../../assets/images/articleEye/SectionImg3.webp'

export const postDetails={
    img:MainImg,
    title:"Change In Eye Colour And Why It Warrants Medical Attention",
    views:"38K",
    date:"NOVEMBER 9,2023 ",
    text:<>
   <p>Each person’s eye colour is unique, with no two people having the same shade despite sharing the same hue, like brown, blue, or green. Moreover, the eye colour you are born with remains the same throughout your life, so if you see any changes during your adult years, you may need to visit an eye doctor as soon as possible.</p>										
   <h5>Sleep disorders</h5>
   <b>What causes your eye colour to change?</b>
   <p>A change in eye colour is incredibly concerning once you are already an adult, but not so much when you are still an infant since the shift in hue is just a normal part of growing up. Doctors have observed that most babies are born with a bluish or greyish tinge in their eyes, and it takes up to three years on average before their eye colour settles on a permanent shade. Genetics and melanin, a naturally occurring pigment, are the two main factors determining a person’s eye colour, and a higher melanin production leads to darker-coloured eyes.</p>
   <p>Thus, when you wake up one day and suddenly notice your eyes look different than before, the potential reasons for the sudden change may include:</p>
   <b>1. Cataracts</b>
   <p>Everything our eyes perceive is filtered through a lens found at the back of the iris or the coloured part of your eye. This lens is akin to a clear disc that focuses light onto the retina behind the eye, which enables our brain to process what we are looking at.</p>
   <p>But due to various factors (the most influential of which is ageing), this lens can turn cloudy white and develop into a cataract, impairing vision to the affected eye and showing through the pupil such that it makes your eye colour noticeably different.</p>
   <b>2. Virus or disease process</b>
   <p>Some viruses or diseases can affect the eyes and cause the iris to slightly change in colour, often to a lighter shade, because of a loss of pigmentation or atrophy due to the thinning of the muscles in your iris. Some conditions that can bring a colour-changing effect on your eyes include:</p>
   <b>Pseudoexfoliation Syndrome</b>
   <p>Pseudoexfoliation syndrome is an age-related condition that causes a flaky and whitish material to develop in the lens, iris, and other parts of the eye, as well as clog its drainage system, increasing one’s risk of glaucoma.</p>
   <b>Pigment Dispersion Syndrome</b>
   <p>Pigment dispersion syndrome leads to pigment loss from the iris, causing atrophy to this part of the eye, blurred vision, and glaucoma. On a related note, glaucoma medications are known to also cause changes in eye colour.</p>
   <b>3. Corneal disease</b>
   <p>There are many instances when the eye’s cornea becomes scarred or discoloured due to infections, swelling, disease, or injury, creating a ‘foggy’ layer that blocks your view of your coloured iris and making it look like your eye is turning white. To mitigate the long-term effects of corneal issues, you should speak to a Virtual Doctor In India as soon as you notice whitening in your eyes.</p>
   <b>4. Inflammation</b>
   <p>Medical conditions that trigger inflammation in the eye can cause it to change colour. Examples include:</p>
   <b>Fuchs Heterochromic Iridocyclitis (FHI)</b>
   <p>One of the symptoms of FHI includes pigmentation loss and a change in colour in the iris of the affected eye. If left unchecked, FHI may lead to optic nerve damage and glaucoma.</p>
   <b>Uveitis</b>
   <p>Light sensitivity and Reddening Of The White Part of the eye are two obvious symptoms of uveitis, a condition that causes vision loss if left untreated. Apart from these signs, uveitis can also change the size or shape of the pupil and thus make your iris look different.</p>
   <b>5. Injury</b>
   <p>A blow near or directly to the eye will naturally cause injury that can temporarily alter how your eye looks should hyphema or bleeding occur inside the eye. The bleeding often causes blood to pool behind the iris and cornea, darkening their colour sometimes to the point that they almost look black.</p>
   <b>Conclusion</b>
   <p>Before being worried about your health, it is recommended to first check if there may be a more harmless reason for the change in your eye colour. For instance, if you have light-coloured eyes, your clothes may cause an optical illusion that brings out more of the green or blue in your eyes. Apart from what you are wearing, makeup, lighting, and other environmental factors can also have a similar effect.</p>
   <p>If you have any health concerns that warrant medical attention, MYCLNQ can help you find the answers you need. As the leading app for Telemedicine In India, MYCLNQ offers swift and convenient access to medical services, including online doctor consultations, medical e-commerce, and a Private Ambulance Service In India. To learn more about how MYCLNQ can help with your family’s healthcare needs, feel free to contact us anytime.</p>
   <p>Reference: <a href="https://www.aao.org/eye-health/tips-prevention/why-are-my-eyes-changing-color" target="_blank" rel="noopener noreferrer">American Academy of Ophthalmology</a></p>							
    </>,
}


export const recentPost=[
    {
        id:1,
        img:Img1,
        heading:"SEEING THIN RED LINES UNDER YOUR NAILS? IS IT ENDOCARDITIS?",
        date:"Sep 27, 2023",
        link:"/articleEndoCarditis",
        border:true
    },
    {
        id:2,
        img:Img2,
        heading:"Treating Chronic Migraines: What To Do Next If Nothing Helps",
        date:"Oct 26, 2023",
        link:"/articleMigrane",
        border:true
    },
    {
        id:3,
        img:Img3,
        heading:"5 Home Remedies To Ease Your Jaw Pain And Find Relief",
        date:"Oct 20, 2023",
        link:"/articleRemedies",
        border:false
    },
    ]