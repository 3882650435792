import React from 'react'
import Nav from '../Components/Nav'
import Footer from '../Components/Footer'
import NewsMain from '../Components/NewsMain'
import { recentPost, postDetails} from "../Components/Lists/newsCellcardPartner"
import GoToTop from '../Components/GoToTop'

const NewsCellcardPartner = () => {
  return (
    <>
    <Nav/>
    <NewsMain recentPost={recentPost} postDetails={postDetails}/>
    <Footer/>
    <GoToTop/>
    </>
  )
}

export default NewsCellcardPartner