
export const quickLinkList=[
    {
     id:1,
     text:"Home",
     path:"/"
   },
   {
    id:2,
    text:"About Us",
    path:"/about"
  },
  {
    id:3,
    text:"News & Articles",
    path:"/newsArticles"
  },

  {
    id:4,
    text:"Contact Us",
    path:"/Contact"
  },

]
export const productList=[
    {
     id:1,
     text:"Doctor for you",
     path:"/DoctorsForYou"
   },
   {
    id:2,
    text:"Care Wagon",
    path:"/CareWagon"
  },
  {
    id:3,
    text:"Caregiver",
    path:"/CareGiver"
  },
  {
    id:4,
    text:"Healthcare Packages",
    path:"/healthCarePackage"
  },
]