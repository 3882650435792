import React, {useContext, useState}from 'react'
import { MdBloodtype } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { IoMdArrowDroprightCircle } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import DataContext from '../../hooks/DataContext';
import Button from './Button';

const Table1 = ({isActive ,editHeight, editPadding}) => {
  const [isOpen,setIsOpen]=useState(false);
  const {openModal} =useContext(DataContext);
const navigate=useNavigate();
  const toggleList=()=>{
      setIsOpen(!isOpen);  
  }
  const handleOpenModal = (number) => {
  openModal(number);
  navigate('/healthCareBooking');
  console.log(number)

  // Navigate to the /healthcare-details page
  
  };
  return (
    <>
       <div className=" flex-[0_0_100%] max-w-[100%] lg:flex-[0_0_31%] lg:max-w-[31%] relative w-full min-h-[1px] px-[15px] ">
                <div className={` bg-[#fff] border border-[#ddd]	 text-center relative
                 overflow-hidden my-[15px] pt-[45px] pr-[35px] pb-[30px] pl-[35px] ${isActive ? `${editHeight}` : 'lg:min-h-[50rem]'} `}>
                    {/* <!-- Table Head --> */}
                    <div className=" text-center">
                        <div className=" flex items-center justify-center">
                            <MdBloodtype className='text-[65px] text-reddish font-normal'/>
                        </div>
                        <h4 className=" text-navfont text-[21px] mt-[30px] mb-[15px] 
                      font-medium leading-[1.2]">CARE</h4>
                        <div className="price">
                            <p className="text-[36px] font-semibold text-reddish">INR 400<span className=' inline-block text-sm font-normal
                           text-textAsh ml-[8px] leading-[24px]'>3 Tests</span></p>
                        </div>	
                    </div>
                    {/* <!-- Table List --> */}
                    <ul className=" py-[10px] text-left mt-[30px]">
                      
                        <li className=" table-list relative text-[#666] capitalize mb-[18px] pr-[32px]">Additional Benefits : </li>
                        <li className='relative  table-list text-[#666] capitalize mb-[18px] pr-[32px]'>- Physicians review</li>
                        <li className='relative  table-list text-[#666] capitalize mb-[18px] pr-[32px]'><FaCheckCircle className='text-[10px] mr-[10px] absolute 
            right-0 h-[20px] w-[20px] leading-[20px] text-center text-reddish
             bg-[#fff] rounded-full pl-[1px]'/>Cholestrol Total</li>
                        <li className="relative  table-list text-[#666] capitalize mb-[18px] pr-[32px]"><FaCheckCircle className='text-[10px] mr-[10px] absolute 
            right-0 h-[20px] w-[20px] leading-[20px] text-center text-reddish
             bg-[#fff] rounded-full pl-[1px]'/>HbA1C (Haemoglobin A1c)</li>
                        <div>
                            <li className="relative  table-list text-[#666] capitalize mb-[18px] pr-[32px]">
                                <IoMdArrowDroprightCircle className={`text-[18px] mr-[10px] absolute 
                            right-[-2px] h-[24px] w-[24px] leading-[22px] text-center text-reddish duration-500
                             bg-[#fff] rounded-full pl-[1px] ${isOpen? 'rotate-90' :'rotate-0'} `}  onClick={()=>toggleList()}
                            />TSH  Ultra sensitive</li>
                            <div className={`sub-list duration-500 ${isOpen? 'visible mb-[1rem] duration-500':'hidden mb-[1rem] '}
                                   `} >
                                <p>- Thyroid Stimulating hormone Ultra sensitive</p>
                            </div>
                        </div>		
                          		
                        </ul>
                        {isActive&&<div className=" w-full flex justify-end text-reddish text-sm font-normal absolute bottom-[98px] left-[-38px]">
								<Link  to={'/healthCarePackage'}>View more</Link>
                              </div>}
                              <div className={`mt-[25px] lg:pt-[14.1rem] ${editPadding}`} >
                                <Button text={'Book Now'} width={'w-full'} handleClick={()=>handleOpenModal(2)} />
							</div>
                    {/* <!-- Table Bottom --> */}
                </div>
            </div>
    </>
  )
}

export default Table1