import React from 'react'
import Nav from '../Components/Nav'
import Footer from '../Components/Footer'
import LegalMain from '../Components/LegalMain'
import GoToTop from '../Components/GoToTop'

const Legal = () => {
  return (
    <>
    <Nav/>
    <LegalMain/>
    <Footer/>
    <GoToTop/>
    </>
  )
}

export default Legal