import React from 'react'
import Nav from '../Components/Nav'
import Footer from '../Components/Footer'
import ClinicDetailsMain from '../Components/ClinicDetailsMain'
import { slides ,doctorDetails,clinicDetails,isActive} from '../Components/Lists/satyaClinic'
import GoToTop from '../Components/GoToTop'

const SathyaClinicPage = () => {
  return (
    <>
    <Nav/>
    <ClinicDetailsMain 
        slides={slides} 
        doctorDetails={doctorDetails} 
        clinicDetails={clinicDetails} 
        isActive={isActive}
    />
    <Footer/>
    <GoToTop/>
    </>
  )
}

export default SathyaClinicPage