import React from 'react'
import sectionImg from '../assets/images/section-img.png'
import app1 from "../assets/images/getApp/1-App-Store.svg.png"
import app2 from "../assets/images/getApp/download-removebg-preview.png"
import app3 from "../assets/images/getApp/3-Huawei-Store.png"
import scanImg from "../assets/images/getApp/getapp.webp"

const GetAppMain = () => {
  return (
    <section className='xl:h-[817px] py-12 md:max-h-[751px] max-h-[630px] w-full flex flex-col justify-center items-center '>  
    <div className=' xl:w-[1519px] md:w-[798px] w-[335px] md:px-[20px]  h-[283px] xl:h-[182px] md:h-[182px]
    xl:px-[250px]  text-center flex flex-col items-center justify-center '>
        <h2 className='  text-[#2C2D3F]  text-[2rem] xl:text-[32px]  font-medium capitalize leading-tight text-center'>
        Download Our App for Seamless Experiences!</h2>
        <img className=' text-center' src={sectionImg} alt='doctors' loading='lazy'/>
        <p className=' md:text-[15px] text-[13px] text-[#888]  leading-6 font-normal'>
        Upgrade your lifestyle with our app—your key to effortless convenience and innovation!</p>
        <div className="flex justify-center items-center  mb-2">
			<div><a href="https://apps.apple.com/in/app/myclnq/id1436460772" target="_blank" rel="noopener noreferrer">
                <img className="h-[50px]" src={app1} alt="apple"/></a></div>
			<div className="mx-5"><a href="https://play.google.com/store/apps/details?id=com.ssivixlab.MYCLNQ&amp;hl=en_SG" target="_blank" 
            rel="noopener noreferrer">
                <img className="h-[70px]" src={app2} alt="palystore"/></a></div>
			<div><a href="https://appgallery.huawei.com/app/C104584547" target="_blank" rel="noopener noreferrer">
                <img className="h-[50px]" src={app3} alt="Huawei"/></a></div>
		</div>
    </div>
    <div className='lg:h-[500px] md:h-[432px] h-[211px] shadow-[0_0_10px_1px_#8888884e]   text-base mx-auto   '>
    <div className="lg:h-[500px]  md:h-[432px]  h-[211px]">									 
		<img className=" h-full w-full rounded" src={scanImg}  alt="scanImg" loading='lazy'/>
     </div>
    </div>
</section>
  )
}

export default GetAppMain