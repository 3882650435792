import MainImg from '../../assets/images/articleMigrane/MainImg.webp'
import Img1 from '../../assets/images/articleMigrane/SectionImg1.webp'
import Img2 from '../../assets/images/articleMigrane/SectionImg2.webp'
import Img3 from '../../assets/images/articleMigrane/SectionImg3.webp'

export const postDetails={
    img:MainImg,
    title:"Treating Chronic Migraines: What To Do Next If Nothing Helps",
    views:"25K",
    date:"OCTOBER 26, 2023 ",
    text:<>
                                        <p>For many people, migraines are headaches that come with many other symptoms that can disrupt one’s daily life, including nausea and vomiting, difficulty concentrating, fatigue, and pain. While there are simple remedies to ease the headache, they may not work if your migraines are considered chronic. And if you are among those diagnosed with chronic migraines, trying to find an effective way to stop the condition or at least manage the symptoms can be just as irritating as dealing with the pounding pain in your head.</p>
                                        <p>According to healthcare experts, people with chronic migraines experience at least 15 or more headache days per month. If you are among the many who find spending half of every month with headaches unbearable, you would naturally spare no effort in trying everything to stop your migraines from taking over your life.</p>
                                        <p>However, what can you do next if nothing has worked so far? Below, we offer some tips that you may find useful in your search for the solution you need.</p>
                                        <b>Why nothing helps your chronic migraines</b>
                                        <p>Although there are many treatment options to address migraines, there is no one specific medication that can cure it for everyone. As such, guesswork is often necessary to determine which medication or treatment works best for your case. This applies to pain management in general as doctors cannot fully predict how each patient responds to certain medicines, hence the need for trial-and-error to find the most effective and tolerable solution for you.</p>
                                        <p>Because of this, it is crucial to speak regularly to your doctor, be it through a physical or online medical consultation, about how you feel beyond the constant headaches. Providing information regarding triggers, frequency, and associated symptoms of your headaches lets your doctor make the proper diagnosis and provide you with the best treatment plan.</p>
                                        <p>Lastly, it is recommended to be patient when starting a new medication and not be frustrated when it does not work immediately. This is simply how most oral medications for migraines are, hence why healthcare providers advise giving each medicine a six or eight-week trial or ideally three months if you can tolerate it.</p>
                                        <b>What you can do next to manage your migraines</b>
                                        <b>1. Reevaluate your lifestyle habits and headache triggers</b>
                                        <p>Take a pause from finding the right medication and consider backtracking to reexamine your lifestyle and the potential triggers of your headaches. This is because certain lifestyle factors can play a significant role in managing migraines. For instance, even if you are on the best migraine medication, a lack of good sleep and uncontrolled anxiety may still contribute to your headache troubles. By evaluating every aspect of your lifestyle, you and your doctor can determine which ones need to be tweaked or addressed to help with your migraines.</p>
                                        <b>2. Ask about new treatments</b>
                                        <p>If you have been taking certain medications for some time now yet still do not experience significant relief, consider asking your healthcare provider if they can prescribe newer options for treating chronic migraines. Thanks to the continued research on chronic migraines, more and more medicines specifically developed for it are becoming available. Naturally, these would be more effective than medicines for blood pressure, anxiety, and seizures, which have side effects.</p>
                                        <b>3. Try adding alternative therapies to your treatment</b>
                                        <p>Apart from your prescribed preventive and rescue medication, you should consider searching for other methods to manage your migraines. Many alternative therapies could prove helpful in managing your migraines, such as acupuncture, biofeedback, and even cognitive behavioural therapy. Besides therapies, you can also try out supplements that have been studied and are proven to reduce migraine frequency, including vitamin B2, magnesium, and coenzyme Q10.</p>
                                        <b>Conclusion</b>
                                        <p>Finding the right treatment for your migraines is not an impossible task. By taking a holistic view of your life, you can determine whether your medication needs tweaking, there are other triggers that you may have missed, or you can do more to reduce your stress and anxiety.</p>
                                        <p>The last important piece to this puzzle is working closely with a healthcare provider since they are instrumental in navigating your way to a pain-free and healthy life. With the MYCLNQ app, connecting with medical experts who can help you with your migraines has never been easier. In just a few clicks, you can consult with a certified healthcare provider who is best suited to address your concerns and access key medical services from the comforts of your home, such as private ambulance transport and virtual ART test in Singapore. For more information about what MYCLNQ can do for you, feel free to contact us anytime.</p>
                                        <p>Reference: <a href="https://health.clevelandclinic.org/nothing-helping-migraine/" target="_blank" rel="noopener noreferrer">Cleveland Clinic</a></p>
                                   
    </>,
}


export const recentPost=[
    {
        id:1,
        img:Img1,
        heading:"Change In Eye Colour And Why It Warrants Medical Attention",
        date:"Nov 9, 2023",
        link:"/articleEye",
        border:true
    },
    {
        id:2,
        img:Img2,
        heading:"SEEING THIN RED LINES UNDER YOUR NAILS? IS IT ENDOCARDITIS?",
        date:"Sep 27, 2023",
        link:"/articleEndocarditis",
        border:true
    },
    {
        id:3,
        img:Img3,
        heading:"5 Home Remedies To Ease Your Jaw Pain And Find Relief",
        date:"Oct 20, 2023",
        link:"/articleRemedies",
        border:false
    },
    ]