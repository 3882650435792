import MainImg from '../../assets/images/newsFinance/MainImg.webp'
import Img1 from '../../assets/images/newsFinance/SectionImg1.webp'
import Img2 from '../../assets/images/newsFinance/SectionImg2.webp'
import Img3 from '../../assets/images/newsFinance/SectionImg3.webp'


export const postDetails={
    img:MainImg,
    title:"Visa Study Shows Card Use Growing Among Cambodians, Fueling Financial Inclusion",
    views:"32K",
    date:"JULY 27, 2023",
    text:<>

                                        <p>Visa study of Cambodians reveals growing interest in and frequency of use of contactless cards.</p>
                                        <p>Visa, a world leader in digital payments, announced that its latest Visa Consumer Payment Attitudes Study1 shows Cambodian consumers are gradually becoming more familiar with the benefits of using credit/debit cards, a key gateway to financial inclusion into the formal economy.</p>
                                        <p>The study showed that while cash usage is still widespread in Cambodia, the use of credit/debit cards for payment is growing in popularity, with 35% of survey respondents saying they currently use them. Interest in contactless cards in particular is growing, up to 58% from 42% in 2021, while 9% of survey respondents said they currently use contactless cards for payment.</p>
                                        <p>For those who use credit/debit cards, the study showed frequency of usage is growing. 78% of contactless card users say they use them at least once a week, up from 37% in 2020, while use by card swipe/insert (52% from 36% in 2020) and online (77% from 43% in 2020) also increased. Among survey respondents that have credit/debit cards and e-wallets, 63% said they prefer to use cards, with the top reasons for their preference being that cards are faster, more convenient and easier to carry around.</p>
                                        <p>Digital payments are often the first formal financial product a person uses, and serve as a pathway to financial inclusion. About half the adult world lives in the informal economy, dealing exclusively in cash and facing barriers that make life risky, expensive and inefficient2. Financial inclusion helps put people on a path out of poverty, creates productive empowered citizens, fosters business opportunities and fuels economic growth.</p>
                                        <p>Credit cards can offer a better value proposition for consumers in terms of flexibility of repayment, building a positive credit history and digital transparency3. As consumers increasingly seek the convenience and security of digital payment, businesses that can provide cashless options are often rewarded with larger, more loyal customer bases.</p>
                                        <p>“We are excited to see Cambodians embracing digital payments as accessing formal financial services is key to bolstering financial inclusion and Cambodia’s economic development,” said Ms. Ivana Tranchini, Visa Country Manager for Cambodia. “Unlocking modern forms of credit in the evolving digital credit landscape lowers the barrier to entry for financial services and brings more Cambodians into the formal financial sector, encouraging safe and responsible payment transactions.”</p>
                                        <p>Along with credit cards, Buy Now Pay Later (BNPL) plans give banks a tool to support customers who face adversity with short-term financing, allowing customers to transition to other forms of credit and helping them build their credit history and financial stability. In other countries/territories, banks have successfully used BNPL for credit provisioning and credit inclusion.</p>
                                        <p>Cambodians said they use BNPL plans mostly in-store (58%) or on e-Commerce websites (52%), and decide to use BNPL while shopping (53%), during check out (46%) or before shopping (40%). The average number of BNPL plans used by survey respondents in the last two years is high at 6.6 – mostly in categories such as travel, health &amp; beauty and home appliances.</p>
                                        <p>Visa is constantly looking for ways to promote digital payment usage in Cambodia to empower consumers with secure and convenient customer experiences. For instance, Visa recently partnered with Cambodia’s Cellcard and Singapore’s MyCLNQ to launch Cellcard’s E-Heath Service, which provides quality health care accessible by mobile phone. E-Health Service is initially bundled with Cellcard’s Tourist SIM, and visitors to Cambodia can save 10% on the service and medical bills by purchasing the service with their Visa cards.</p>
                                        <p>“We applaud the government and organizations for playing a key role in delivering public financial literacy education to enable Cambodians to build their knowledge and trust in the financial sector,” added Ms. Tranchini. “Digital innovation in the credit landscape presents an exciting opportunity for financial inclusion and progress towards a more transparent and sustainable ecosystem.”</p>
                                 
    </>
}


export const recentPost=[
    {
        id:1,
        img:Img1,
        heading:"Change In Eye Colour And Why It Warrants Medical Attention",
        date:"Nov 9, 2023",
        link:"/articleEye",
        border:true
    },
    {
        id:2,
        img:Img2,
        heading:"Treating Chronic Migraines: What To Do Next If Nothing Helps",
        date:"Oct 26, 2023",
        link:"/articleMigrane",
        border:true
    },
    {
        id:3,
        img:Img3,
        heading:"5 Home Remedies To Ease Your Jaw Pain And Find Relief",
        date:"Oct 20, 2023",
        link:"/articleRemedies",
        border:false
    },
    ]