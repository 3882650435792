import MainImg from '../../assets/images/articleSleep/MainImg.webp'
import Img1 from '../../assets/images/articleSleep/SectionImg1.webp'
import Img2 from '../../assets/images/articleSleep/SectionImg2.webp'
import Img3 from '../../assets/images/articleSleep/SectionImg3.webp'
import blog1 from '../../assets/images/articleSleep/blog1.webp'
import blog2 from '../../assets/images/articleSleep/blog2.webp'
import bgImg from '../../assets/images/articleSleep/blockqoute-bg.webp'
const blogs=[
{
    id:1,
    img:blog1
},
{
    id:2,
    img:blog2
},
]

export const postDetails={
    img:MainImg,
    title:"Feeling Tired And Why It Can Persist Despite Sleeping Well",
    views:"33K",
    date:"OCT 4,  2023 ",
    text:<>

		<p>Everyone knows that getting enough sleep is the key to feeling rested. However, it can be frustrating to still feel tired despite 
            following this golden rule to the T. Furthermore, there is also the possibility that despite meeting the recommended hours or quantity of sleep,
             the quality of your slumber is not up to par. In the following sections, we discuss the potential reasons why you still wake up tired in the morning.</p>
<h5>Sleep disorders</h5>
<p>Feeling tired or experiencing brain fog during the day are often caused by underlying sleep disorders. An example of this is sleep apnea, 
    a common condition wherein breathing temporarily stops and restarts many times while sleeping and causes one to constantly feel exhausted.
     Another sleep disorder that affects many people is restless legs syndrome, wherein there is an uncontrollable urge to move one’s legs 
     due to an uncomfortable sensation. Moving temporarily eases this unpleasant feeling and ultimately causes fogginess and insomnia in some cases. 
     Other types of sleep disorders whose symptoms include tiredness during the day are:
</p><ol className='list-disc ml-4'>
		<li>Narcolepsy</li>
		<li>Hypersomnia</li>
		<li>Insomnia</li>
		</ol>
<p></p>
<h5>Diet</h5>
<p></p>
<p></p>
<div className="mb-[20px]">
	<div className="flex flex-row mx-[-15px]">
        {blogs.map(({id,img})=>
        <div className="h-[214px] w-[350px] px-[15px]" key={id}>
        <div className="h-[214px] w-[320px] hover:scale-[1.2] duration-500 ">
            <img src={img} alt="blogs"/>
        </div>
    </div>
        )}
			</div>
	</div>
<p>Regularly eating big meals and drinking alcohol before bedtime can severely disrupt and reduce 
    sleep quality. When it comes to the latter, health experts will advise against drinking within four hours before sleeping 
    as it can result in poor sleep quality throughout the night. While it may seem like it is doing the opposite and actually helps
     you doze off, the truth is that it causes your sleep to become fragmented and changes your sleep stages, which diminishes the overall 
     quality of your sleep.</p>
<h5>Mental health conditions</h5>
	<blockquote className="relative bg-cover bg-center bg-no-repeat p-[30px] overflow-hidden before:absolute mb-4
     before:left-0 before:top-0 before:h-full before:w-full before:bg-reddish before:opacity-[0.8]"  style={{ backgroundImage:`url(${bgImg})`}}>
<p className=' text-[#fff] leading-[26px] text-[15px] relative'>Suppose you have verified that sleep disorders or other sleep-related conditions are not causing your tiredness. 
    In that case, it may be best to speak to a virtual doctor in Singapore to check whether your problem is due to 
    stress or an underlying mental health problem.</p>
<p className=' text-[#fff] leading-[26px] text-[15px] relative'>Depression, anxiety, and other related conditions generally make you feel tired despite getting high-quality and high-quantity sleep. Moreover, mood disorders like premenstrual dysphoria and bipolar disorder can also cause sluggishness during waking hours.</p>
	</blockquote>
<h5>Thyroid problems</h5>
<p>Daytime fatigue is often a sign of a thyroid problem, specifically an underactive thyroid. This condition occurs when the thyroid organ does not make enough thyroid hormone, which slows down one’s metabolism and causes constant fatigue.</p>
<h5>Anaemia</h5>
<p>In some cases, persistent exhaustion could also indicate anaemia. Experiencing iron deficiency can make one feel low on energy despite getting a full eight hours of rest. Besides fatigue, other common symptoms of anaemia include:
  </p><ol className='list-disc ml-4'>
			<li>Chest Pain</li>
			<li>Shortness Of Breath</li>
			<li>Dizziness</li>
			<li>Pale Skin</li>
			<li>Pounding Sound In The Ears</li>
										</ol>
										<p></p>
<h5>Tips to improve your sleep quality</h5>
<h5>1. Avoid screen time before bed</h5>
										<p>Whether you are scrolling on your phone or watching television, any screen time before bed is sure to negatively affect your sleep quality to some degree, as the light from these devices is known to interfere with our sleep patterns. If you cannot make your bedroom a device-free zone, at the very least, stop using your gadgets an hour before bed.</p>
<h5>2. Create a sleep-conducive environment</h5>
										<p>Your sleeping environment must be comfortable, soothing, and free of distractions to ensure no external factors will affect your slumber. Sleeping in a quiet, cool, and dark room is optimal for most people, but if you have slightly different preferences, feel free to customise your room to meet your needs.</p>
<h5>3. Exercise regularly</h5>
										<p>While being physically active during the day can help improve sleep quality, avoiding strenuous exercise hours before bed is best since it can stimulate your body and cause the opposite effect.</p>
<h5>4. Limit alcohol and caffeine intake</h5>
										<p>It goes without saying that you should avoid caffeine and alcohol close to bedtime as they are known to reduce sleep quality by disrupting your sleep patterns.</p>
<h5>Conclusion</h5>
										<p>Sleep is essential to a healthy lifestyle. As such, if your body is showing signs that something is amiss, make sure to listen. If you still feel tired or fatigued despite doing everything you can to fix your sleep, it may be best to see a sleep disorder specialist to get to the root of your problem.</p>
										<p>With the MYCLNQ app, reaching out to these specialists and other licensed healthcare providers and booking an appointment is easier than ever. From online medical consultations to private ambulance transport, supervised ART testing, and more, MYCLNQ lets you access the key medical services you need in just a few clicks. To learn more about our services, don’t hesitate to contact us today.</p>
								
    </>,
}


export const recentPost=[
    {
        id:1,
        img:Img1,
        heading:"Change In Eye Colour And Why It Warrants Medical Attention",
        date:"Nov 9, 2023",
        link:"/articleEye",
        border:true
    },
    {
        id:2,
        img:Img2,
        heading:"Treating Chronic Migraines: What To Do Next If Nothing Helps",
        date:"Oct 26, 2023",
        link:"/articleMigrane",
        border:true
    },
    {
        id:3,
        img:Img3,
        heading:"5 Home Remedies To Ease Your Jaw Pain And Find Relief",
        date:"Oct 20, 2023",
        link:"/articleRemedies",
        border:false
    },
    ]